<template>
  <div class="jh-dialog-wrap">
    <div class="jh-dialog-header">
      <h1>{{ headerTitle }}</h1>
      <div class="is-right">
        <v-btn class="jh-btn is-icon" @click="$emit('hide')"><i class="jh-icon-close-lg is-white"></i></v-btn>
      </div>
    </div>
    <div class="jh-dialog-body">
      <v-data-table
        class="jh-grid"
        height="200"
        :headers="divGridAuthGroup"
        :items="gridDataText"
        :item-class="isActiveRow"
        :items-per-page="30"
        item-key="ATRT_GROUP_ID"
        :page.sync="page"
        fixed-header
        hide-default-footer
        single-select
        @page-count="pageCount = $event"
        @click:row="getGrid2ListSel"
      >
      </v-data-table>
      <div class="jh-pager">
        <v-pagination v-model="page" :length="pageCount" :total-visible="totalVisible"></v-pagination>
        <span class="jh-counter is-left">총 <em>{{ gridDataText.length }}</em> 건</span>
      </div>

      <div class="jh-ui-header">
        <h2>상세정보</h2>
        <div class="is-right">            
        </div>
      </div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <table class="jh-tbl-detail">
          <colgroup>
            <col width="120px">
            <col>
            <col width="120px">
            <col>
            <col width="120px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th><label class="is-required">그룹명</label></th>
              <td colspan="5">
                <v-text-field
                  class="jh-form is-md"
                  name="ATRT_GROUP_NM"
                  id="id"
                  placeholder="그룹명"
                  :rules="validateRules.artrGroupNm"
                  v-model="selArtrGroupNm.ATRT_GROUP_NM"
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th><label class="is-required">우선순위</label></th>
              <td>
                <v-text-field
                  class="jh-form is-md"
                  name="ETC_INFO01"
                  id="id"
                  placeholder="우선순위"
                  :rules="validateRules.etcInfo01"
                  v-model="selArtrGroupNm.ETC_INFO01"
                >
                </v-text-field></td>
              <th><label class="is-required">관리자여부</label></th>
              <td>
                <v-select
                  class="jh-form is-md"
                  :items="menuGroup2"
                  name="MNGR_YN"
                  item-text="name"
                  item-value="value"
                  placeholder="선택"
                  :rules="validateRules.mngrYn"
                  v-model="selArtrGroupNm.MNGR_YN"
                ></v-select>
              </td>
              <th><label class="is-required">상담사구분</label></th>
              <td>
                <v-select
                  class="jh-form is-md"
                  :items="menuGroup3"
                  name="ETC_INFO02"
                  item-text="name"
                  item-value="value"
                  placeholder="선택"
                  :rules="validateRules.etcInfo02"
                  v-model="selArtrGroupNm.ETC_INFO02"
                ></v-select>

              </td>
            </tr>
            <tr>
              <th><label>그룹설명</label></th>
              <td colspan="5">
                <v-text-field
                  class="jh-form is-md"
                  name="GROUP_XPLN"
                  id="id"
                  placeholder="그룹설명"
                  :rules="validateRules.groupXpln"
                  v-model="selArtrGroupNm.GROUP_XPLN"
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th><label>그룹ID</label></th>
              <td colspan="5">
                <v-text-field
                  class="jh-form is-md"
                  name="ATRT_GROUP_ID"
                  id="id"
                  disabled
                  placeholder="그룹ID"
                  :rules="validateRules.atrtGroupId"
                  v-model="selArtrGroupNm.ATRT_GROUP_ID"
                >
                </v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-form>
    </div>
    <div class="jh-dialog-footer" v-if="this.mixin_set_btn(this.$options.name, 'btnSave')">
      <v-btn class="jh-btn is-md" @click="initUser" v-if="this.mixin_set_btn(this.$options.name, 'btnNew')">신규</v-btn>
      <v-btn class="jh-btn is-md is-del" @click="popDelete" v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')">삭제</v-btn>
      <v-btn class="jh-btn is-md is-main" @click="popSubmit">저장</v-btn>
    </div>
  </div>
</template>

<script>
import api from "@/store/apiUtil";
import {mixin} from "@/mixin/mixin";

export default {
  name: "MENU_E040105P01", //name은 'MENU_' + 파일명 조합
  props: {
    param: {
      type: Object,
    },
    headerTitle: String,
  },
  mixins:[mixin],
  data(){
    return {
      type:'',
      REGR_DEPT_CD:'',
      Org_EtcInfo01:'',
      Org_MngrYn:'',
      Org_artrGroupId:'',
      Org_GroupXpln:'',
      Org_ArtrGroupNm:'',
      Org_etcInfo02:'',
      etcInfo01:'',
      etcInfo02:'',
      etcInfo03:'',
      MngrYn:'',
      menuCnt:'',
      artrGroupNm:'',
      artrGroupId:'',
      groupXpln:'',
      menu1: false,
      menu2: false,
      date: {},
      date2: {},
      valid: true,
      validateRules:{
        artrGroupNm: [
          v => !!v || '그룹명은(는) 필수입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        etcInfo01: [
          v => !!v || '우선순위은(는)  필수입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        mngrYn: [
          v => !!v || '관리자여부 은(는)  필수입니다.',
        ],
        newUseYn: [
          v => !!v || '사용여부 은(는)  필수입니다.',
        ],
        etcInfo02: [
          v => !!v || '상담사구분 은(는)  필수입니다.',
        ],
      },
    footerHideTitle: "닫기",

    divGridAuthGroup: [
      {
        text: "그룹명",
        value: "ATRT_GROUP_NM",
        align: "center",
        sortable: true,
        width: "200px"
      },
      {
        text: "우선순위",
        value: "ETC_INFO01",
        align: "center",
        sortable: true
      },
      {
        text: "메뉴개수",
        value: "MENU_CNT",
        align: "center",
        sortable: true,
      },
      {
        text: "그룹ID",
        value: "ATRT_GROUP_ID",
        align: "center",
        sortable: true,
        width: "300px"
      },
    ],
    menuGroup2: [
      { name: "허용", value:"Y" },
      { name: "미허용", value:"N" },
    ],
    menuGroup3: [
      { name: "상담사", value:"01" },
      { name: "관리자", value:"00" },
    ],

    gridDataText: [],
      selArtrGroupNm:{},
    gridRowDetail: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    totalVisible: 10,
    textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
    textareaValue: "200자 제한!",
  }
  },

  methods:{
    validate () {
      return this.$refs.form.validate();
    },
    initUser(){
      this.type = "I"
        console.log("상태 메시지"+this.type);
        this.selArtrGroupNm.ATRT_GROUP_ID = "";
        this.selArtrGroupNm.ATRT_GROUP_NM = "";
        this.selArtrGroupNm.ETC_INFO01 = "";
        this.selArtrGroupNm.MNGR_YN = "";
        this.selArtrGroupNm.ETC_INFO02 = "";
        this.selArtrGroupNm.GROUP_XPLN = "";
    },
    getGrid2ListSel(item){
      this.selArtrGroupNm = JSON.parse(JSON.stringify(item));

      },
    isActiveRow(item){
      return item.ATRT_GROUP_ID== this.selArtrGroupNm.ATRT_GROUP_ID? 'is-active':'';
    },
      async getGrid2(){
        let requestData = {
          headers: {},
          sendData:{}
        };
        // 3개 탭 정보를 보내야 함. 먼저 접수지연
        // header 세팅
        requestData.headers["URL"] = "/api/setting/system/menu-author-manage/author-group/inqire";
        requestData.headers["METHOD"] = "inqire";
        requestData.headers["SERVICE"] = "setting.system.menu-author-manage.author-group";
        requestData.headers["ASYNC"] = false;
        //this.selArtrGroupNm = '';
        let response = await this.common_postCall(requestData);
        this.getGrid2CallBack(response);
      },
    async popDelete(){
      let requestData = {
        headers: {},
        sendData:{}
      };
      // 3개 탭 정보를 보내야 함. 먼저 접수지연
      // header 세팅
      requestData.headers["URL"] = "/api/setting/system/menu-author-manage/asgn-menu-by-group/delete";
      requestData.headers["METHOD"] = "delete";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["SERVICE"] = "setting.system.menu-author-manage.asgn-menu-by-group";
      requestData.headers["ASYNC"] = false;
      let deleteList = [];
      let Obj = {
        DATA_FLAG:"DP",
        ATRT_GROUP_ID: this.selArtrGroupNm.ATRT_GROUP_ID,
      }
      deleteList.push(Obj);
      let Obj2 = {
        DATA_FLAG:"DU",
        ATRT_GROUP_ID: this.selArtrGroupNm.ATRT_GROUP_ID,
      }
      deleteList.push(Obj2);
     let Obj3 = {
        DATA_FLAG:"DG",
       ATRT_GROUP_ID: this.selArtrGroupNm.ATRT_GROUP_ID,
      }
      deleteList.push(Obj3);
      requestData.sendData  = {
        list:deleteList
      }
      let response = await this.common_postCall(requestData);
      this.popDeleteCallBack(response);
    },
    popDeleteCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.common_alert("정상처리 되었습니다.");
      this.getGrid2();
      this.selArtrGroupNm = "";
      this.$refs.form.resetValidation()
      this.$emit('hideAndGetList');
      return;
    },
    async initUserSbmit(){
      let requestData = {
        headers: {},
        sendData:{}
      };
      requestData.headers["URL"] = "/api/setting/system/menu-author-manage/author-group-nm-dplct-ceck/inqire";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["SERVICE"] = "setting.system.menu-author-manage.author-group-nm-dplct-ceck";
      requestData.headers["ASYNC"] = false;

      requestData.sendData = {};
      requestData.sendData["ATRT_GROUP_ID"] = this.selArtrGroupNm.ATRT_GROUP_ID;
      requestData.sendData["ATRT_GROUP_NM"] = this.selArtrGroupNm.ATRT_GROUP_NM;
      requestData.sendData["ETC_INFO01"] = this.selArtrGroupNm.ETC_INFO01;
      requestData.sendData["MNGR_YN"] = this.selArtrGroupNm.MNGR_YN;
      requestData.sendData["ETC_INFO02"] = this.selArtrGroupNm.ETC_INFO02;
      requestData.sendData["ETC_INFO03"] = this.selArtrGroupNm.ETC_INFO03;
      requestData.sendData["REGR_DEPT_CD"] = 'x';
      requestData.sendData["GROUP_XPLN"] = this.selArtrGroupNm.GROUP_XPLN;
      requestData.sendData["AMDR_DEPT_CD"] = "x";
      let response = await this.common_postCall(requestData);
      this.initUserSbmitCallBack(response);
    },
    initUserSbmitCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      if(Object.keys(data).length<1){
        this.initUserIdSbmit();
      }
    },
    async initUserIdSbmit(){
      let requestData = {
        headers: {},
        sendData:{}
      };
      requestData.headers["URL"] = "/api/innb/common/generate-unique-no/inqry";
      requestData.headers["METHOD"] = "inqry";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["SERVICE"] = "innb.common.generate-unique-no";
      requestData.headers["ASYNC"] = false;

      requestData.sendData = {};
      requestData.sendData["BIZ_CASE"] = "TWB";
      requestData.sendData["REGR_DEPT_CD"] = 'x';
      let response = await this.common_postCall(requestData);
      this.initUserIdSbmitCallBack(response);
    },
    initUserIdSbmitCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.selArtrGroupNm.ATRT_GROUP_ID = data[0].RET_VAL;
      this.initUserRegistSbmit();
    },
    async initUserRegistSbmit(){

      if(!this.validate()){
        return;
      }
      let requestData = {
        headers: {},
        sendData:{}
      };
      requestData.headers["URL"] = "/api/setting/system/menu-author-manage/author-group/regist";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["SERVICE"] = "setting.system.menu-author-manage.author-group";
      requestData.headers["ASYNC"] = false;

      requestData.sendData = {};

      requestData.sendData["ATRT_GROUP_ID"] = this.selArtrGroupNm.ATRT_GROUP_ID;
      requestData.sendData["ATRT_GROUP_NM"] = this.selArtrGroupNm.ATRT_GROUP_NM;
      requestData.sendData["ETC_INFO01"] = this.selArtrGroupNm.ETC_INFO01;
      requestData.sendData["MNGR_YN"] = this.selArtrGroupNm.MNGR_YN;
      requestData.sendData["ETC_INFO02"] = this.selArtrGroupNm.ETC_INFO02;
      requestData.sendData["ETC_INFO03"] = this.selArtrGroupNm.ETC_INFO03;
      requestData.sendData["REGR_DEPT_CD"] = 'x';
      requestData.sendData["GROUP_XPLN"] = this.selArtrGroupNm.GROUP_XPLN;
      requestData.sendData["PROC_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      requestData.sendData["AMDR_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      requestData.sendData["AMDR_DEPT_CD"] = "x";
      let response = await this.common_postCall(requestData);
      this.initUserRegistSbmitCallBack(response);
    },
    initUserRegistSbmitCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.common_alert("정상처리되었습니다.");
      this.getGrid2();
      this.selArtrGroupNm = "";
      this.$refs.form.resetValidation()

    },
    async popSubmit(){

      if(!this.$refs.form.validate()) return false
      let requestData = {
        headers: {},
        sendData:{}
      };
      if(this.type == "I"){ //신규
        this.initUserSbmit();
      }else{//수정
        // header 세팅
        requestData.headers["URL"] = "/api/setting/system/menu-author-manage/author-group/modify";
        requestData.headers["METHOD"] = "modify";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["SERVICE"] = "setting.system.menu-author-manage.author-group";
        requestData.headers["ASYNC"] = false;


        requestData.sendData = {};

        requestData.sendData["ATRT_GROUP_ID"] = this.selArtrGroupNm.ATRT_GROUP_ID;
        requestData.sendData["ATRT_GROUP_NM"] = this.selArtrGroupNm.ATRT_GROUP_NM;
        requestData.sendData["ETC_INFO01"] = this.selArtrGroupNm.ETC_INFO01;
        requestData.sendData["MNGR_YN"] = this.selArtrGroupNm.MNGR_YN;
        requestData.sendData["ETC_INFO02"] = this.selArtrGroupNm.ETC_INFO02;
        requestData.sendData["ETC_INFO03"] = this.selArtrGroupNm.ETC_INFO03;
        requestData.sendData["REGR_DEPT_CD"] = 'x';
        requestData.sendData["GROUP_XPLN"] = this.selArtrGroupNm.GROUP_XPLN;
        requestData.sendData["PROC_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
        requestData.sendData["AMDR_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
        requestData.sendData["AMDR_DEPT_CD"] = "x";

      }
      let response = await this.common_postCall(requestData);
      this.popSubmitCallBack(response);
    },

    popSubmitCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
     // this.gridDataText = data;
      this.common_alert("정상적으로 수정되었습니다.");
      this.$emit('hideAndGetList');
      this.getGrid2();
      return;
    },

      getGrid2CallBack(response){
        let header = response.HEADER;
        let data = response.DATA;
        this.gridDataText = data;
        //this.common_alert("정상처리 되었습니다.");
        this.$emit('hideAndGetList');
        return;
      },
    },
    mounted(){
      // 헤더 초기화 및 세팅
      this.getGrid2();
      //this.init();
    },

    computed: {
      initHeaders() {
        return {
          SERVICE: '',
          METHOD: "",
          TYPE: 'BIZ_SERVICE',
        };
      },
      paramData(){
        return {
          ATRT_GROUP_NM : this.param.data.ATRT_GROUP_NM,
          ETC_INFO01 : this.param.data.ETC_INFO01,
          MNGR_YN : this.param.data.MNGR_YN,
          ATRT_GROUP_ID : this.param.data.ATRT_GROUP_ID,
          ETC_INFO02 : this.param.data.ETC_INFO02,
          GROUP_XPLN : this.param.data.GROUP_XPLN,

      };
    }
  },
  };
</script>

<style>

</style>
