<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>메뉴권한관리</h1>
      <div class="is-right">
      </div>
    </div>

    <!--조회-->
    <div class="jh-search-form">
      <table>
        <colgroup>
            <col width="55px">
            <col width="200px">
            <col width="70px">
            <col width="150px">
            <col>
        </colgroup>
        <tr>
          <th><label>메뉴그룹</label></th>
          <td>
            <v-select class="jh-form" :items="dropItems" item-text="CD_NM" item-value="CD" v-model="menuGroup" placeholder="선택"></v-select>
          </td>
          <th><label>권한그룹</label></th>
          <td>
            <v-select class="jh-form" :items="dropItems2" item-text="CD_NM" item-value="CD" v-model="atrtGroupId" placeholder="선택"></v-select>
          </td>
          <td class="has-search"><v-btn class="jh-btn is-search" id="btnSearch" @click="getListOrNot">조회</v-btn></td>
          <td class="is-txt-right">
            <v-btn class="jh-btn" @click="showDialog('E040105P01')" v-if="this.mixin_set_btn(this.$options.name, 'btnPopupAuthGroup')">권한그룹관리</v-btn>
          </td>
        </tr>
      </table>
      <v-dialog max-width="800" persistent v-if="dialogE040105P01" v-model="dialogE040105P01" :eager="true">
        <dialog-E040105P01
            headerTitle="권한그룹관리"
            @hide="hideDialog('E040105P01')"
            @submit="submitDialog('E040105P01')"
        >
        </dialog-E040105P01>
      </v-dialog>
    </div>
    <!--//조회-->

    <div class="jh-cols">
      <!--메뉴-->
      <div class="jh-form-wrap jh-cols is-pb-10">
        <div>          
          <div class="jh-ui-header">
            <h2>미할당 메뉴</h2>
            <div class="is-right">
            </div>
          </div>
          <v-data-table
            class="jh-grid has-control"
            height="565"
            :headers="divGridNoAlloc"
            :items="divGridNoAllocDataText"
            item-key="MENU_ID"
            :item-class="isActiveRow1"
            :page.sync="divGridNoAllocPage"
            @page-count="divGridNoAllocPageCount = $event"
            :items-per-page="30"
            show-select
            fixed-header
            hide-default-footer
            no-data-text="검색된 결과가 없습니다."
            v-model="selectedDivGridNoAllocList"
            @click:row="getGrid1_ListSel"
          >
          </v-data-table>
          <div class="jh-pager">
            <span class="jh-counter is-left">총 <em>{{ divGridNoAllocDataText.length }}</em> 건</span>
          </div>
        </div>
        <div class="is-col-center">
          <v-btn class="jh-btn is-move is-md" @click="moveRight" v-if="this.mixin_set_btn(this.$options.name, 'btnAddArrow1')"><i class="jh-icon-move-right"></i></v-btn>
          <v-btn class="jh-btn is-move is-md" @click="moveLeft" v-if="this.mixin_set_btn(this.$options.name, 'btnDelArrow1')"><i class="jh-icon-move-left"></i></v-btn>
        </div>
        <div>
          <div class="jh-ui-header">
            <h2>할당 메뉴</h2>
            <div class="is-right">
              <v-btn class="jh-btn is-main" id="btnSave" @click="common_confirm('저장하시겠습니까?',submitGrid1,'',null,'','done')" v-if="this.mixin_set_btn(this.$options.name, 'btnSave1')">저장</v-btn>
            </div>
          </div>
          <v-data-table
            class="jh-grid has-control"
            height="565"
            :headers="divGridAlloc"
            :items="divGridAllocDataText"
            :items-per-page="150"
            item-key="MENU_ID"
            :item-class="isActiveRow2"
            :page.sync="divGridAllocPage"
            show-select
            fixed-header
            hide-default-footer
            @page-count="divGridAllocPageCount = $event"
            no-data-text="검색된 결과가 없습니다."
            v-model="selectedDivGridAllocList"
            @click:row="getListSel"
          >
          </v-data-table>
          <div class="jh-pager">
            <span class="jh-counter is-left">총 <em>{{ divGridAllocDataText.length }}</em> 건</span>
          </div>
        </div>
      </div>
      <!--//메뉴-->

      <!--버튼-->
      <div class="jh-form-wrap" style="max-width: 450px;">
        <div class="jh-ui-header">
          <h2>할당 버튼</h2>
          <div class="is-right">
            <v-btn class="jh-btn is-main" id="btnSave03" @click="common_confirm('저장하시겠습니까?',submitBtnGrid1,'',null,'','done')" v-if="this.mixin_set_btn(this.$options.name, 'btnSave2')">저장</v-btn>
          </div>
        </div>
        <v-data-table
          class="jh-grid has-control"
          height="250"
          :headers="divGridAllocBtn"
          :items="divGridAllocBtnDataText"
          :items-per-page="30"
          item-key="BTN_ID"
          :page.sync="divGridAllocBtnPage"
          show-select
          fixed-header
          hide-default-footer
          @page-count="divGridAllocBtnPageCount = $event"
          no-data-text="검색된 결과가 없습니다."
          v-model="selectedDivGridAllocBtnList"
          @click:row="getGrid1_ListSel"
        >
        </v-data-table>
        <div class="jh-btn-area is-mt-15">
          <v-btn class="jh-btn is-move is-md" @click="moveUp" v-if="this.mixin_set_btn(this.$options.name, 'btnDelArrow2')"><i class="jh-icon-move-up"></i></v-btn>
          <v-btn class="jh-btn is-move is-md" @click="moveDown" v-if="this.mixin_set_btn(this.$options.name, 'btnAddArrow2')"><i class="jh-icon-move-down"></i></v-btn>
        </div>
        <div class="jh-ui-header">
          <h2>미할당 버튼</h2>
          <div class="is-right">
            <!-- <v-btn class="jh-btn is-main" id="btnSave03" @click="common_confirm('저장하시겠습니까?',submitBtnGrid1,'',null,'','done')" v-if="this.mixin_set_btn(this.$options.name, 'btnSave2')">저장</v-btn> -->
          </div>
        </div>
        <v-data-table
          class="jh-grid has-control"
          height="250"
          :headers="divGridNoAllocBtn"
          :items="divGridNoAllocBtnDataText"
          :items-per-page="30"
          item-key="BTN_ID"
          :page.sync="divGridNoAllocBtnPage"
          show-select
          fixed-header
          hide-default-footer
          @page-count="divGridNoAllocBtnPageCount = $event"
          no-data-text="검색된 결과가 없습니다."
          v-model="selectedDivGridNoAllocBtnList"
          @click:row="getGrid1_ListSel"
        >
        </v-data-table>
      </div>
      <!--버튼-->
    </div>
  </div>
</template>
<script>
import DialogE040105P01 from "./E040105P01.vue";
import api from "../../store/apiUtil";
import {mixin} from "@/mixin/mixin";
export default {
  name: "MENU_E040105", //name은 'MENU_' + 파일명 조합
  components: {
    DialogE040105P01,
  },
  mixins:[mixin],
  data() {
    return {
      dialogE040105P01: false, //권한그룹관리
      HEADERResult:'',
      DATAResult:'',
      HEADER_SERVICE:'',
      HEADER_TYPE:'BIZ_SERVICE',
      HEADER_GRID_ID:'',
      HEADER_ATRT_GROUP_ID:'',
      headers:{},
      sendData: {},
      URLData:'',
      atrtGroupId:'',
      menuGroup:'',
      menuNm:'',
      atrtGroupNm:'',
      menuId:'',
      dataFlag:'',
      btnId:'',
      scrBtnNm:'',
      scrBtnId:'',
      selectedMenu:'',
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      timeStep: "10:10",
      timeStep2: "05:10",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
      ],
      radioGroup: [
        {
          text: "고객접수시작",
        },
        {
          text: "고객접수중지",
        },
      ],
      radioGroupSelected: "고객접수시작",
      dropItems:[],
      dropItems2:[],
      divGridNoAlloc: [
        {
          text: "메뉴명",
          value: "MENU_NM",
          align: "left",
          sortable: true,
        },
        {
          text: "레벨",
          value: "MENU_DIV_CD",
          align: "center",
          sortable: true,
          width: "100px"
        }
      ],
      divGridAlloc: [
        {
          text: "권한그룹명",
          value: "ATRT_GROUP_NM",
          align: "center",
          sortable: false,
          width: "155px"
        },
        {
          text: "메뉴명",
          value: "MENU_NM",
          align: "left",
          sortable: true,
        },
        {
          text: "레벨",
          value: "MENU_DIV_CD",
          align: "center",
          sortable: true,
          width: "100px"
        }
      ],
      divGridNoAllocDataText: [],
      divGridNoAllocDataText2: [
        {
          index: 1,
          index01:"지식컨텐츠현황"
        },
        {
          index: 2,
          index01:"지식HTML 뷰어"
        },
      ],
      divGridNoAllocPage: 1,
      divGridNoAllocPageCount: 0,
      divGridNoAllocItemsPerPage: 100,
      divGridNoAllocTotalVisible: 10,
      divGridAllocDataText: [],
      divGridAllocPage: 1,
      divGridAllocPageCount: 0,
      divGridAllocItemsPerPage: 100,
      divGridAllocTotalVisible: 10,
      selectedDivGridAllocList:[],
      selectedDivGridNoAllocList:[],
      divGridAllocBtn: [
        {
          text: "버튼ID",
          value: "SCR_BTN_ID",
          align: "left",
          sortable: true,
          with: "180px"
        },
        {
          text: "버튼명",
          value: "SCR_BTN_NM",
          align: "left",
          sortable: true,
        }
      ],
      divGridNoAllocBtn:[
        {
          text: "버튼ID",
          value: "SCR_BTN_ID",
          align: "left",
          sortable: true,
          with: "180px"
        },
        {
          text: "버튼명",
          value: "SCR_BTN_NM",
          align: "left",
          sortable: true,
        }
      ],
      detailData: {},
      divGridAllocBtnDataText:[],
      divGridAllocBtnPage: 1,
      divGridAllocBtnPageCount: 0,
      divGridAllocBtnItemsPerPage: 100,
      divGridAllocBtnTotalVisible: 10,
      divGridNoAllocBtnDataText: [ ],
      divGridNoAllocBtnPage: 1,
      divGridNoAllocBtnPageCount: 0,
      divGridNoAllocBtnItemsPerPage: 100,
      divGridNoAllocBtnTotalVisible: 10,
      selectedDivGridAllocBtnList:[],
      selectedDivGridNoAllocBtnList:[],
      textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
      textareaValue: "200자 제한!",
    };
  },
  methods: {
    groupCodeInqire(){
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/system/menu-author-manage/author-group-code/inqire";
      this.headers["SERVICE"] = "setting.system.menu-author-manage.author-group-code";
      this.headers["METHOD"] = "inqire";
      this.headers["ASYNC"] = false;
      this.result();
    },
    groupCodeInqireCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.dropItems2 = data;
    },

    menuGroupInqire(){
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/system/menu-author-manage/group/inqire";
      this.headers["SERVICE"] = "setting.system.menu-author-manage.group";
      this.headers["METHOD"] = "inqire";
      this.headers["ASYNC"] = false;

      this.result();
    },
    menuGroupInqireCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.dropItems = data;
    },
    getListSel(item){
      this.selectedMenu = item;
      //this.detailData = JSON.parse(JSON.stringify(item));
      this.getDivGridNoAllocBtn(item);//미할당 버튼
      this.getDivGridAllocBtn(item);//할당 버튼
    },
    getDivGridNoAllocBtn(item){//미할당버튼
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/system/menu-author-manage/un-asgn-button/inqire";
      this.headers["SERVICE"] = "setting.system.menu-author-manage.un-asgn-button";
      this.headers["METHOD"] = "inqire";
      this.headers["ASYNC"] = false;
      this.headers["GRID_ID"] = "divGridNoAllocBtn";

      this.sendData= {};
      this.sendData["MENU_ID"] = this.selectedMenu.MENU_ID;
      this.sendData["ATRT_GROUP_ID"] = this.atrtGroupId;
      this.result();
    },
    getDivGridAllocBtn(item){//할당버튼
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/system/menu-author-manage/asgn-button/inqire";
      this.headers["SERVICE"] = "setting.system.menu-author-manage.asgn-button";
      this.headers["METHOD"] = "inqire";
      this.headers["ASYNC"] = false;
      this.headers["GRID_ID"] = "divGridAllocBtn";

      this.sendData= {};
      this.sendData["MENU_ID"] = this.selectedMenu.MENU_ID;
      this.sendData["ATRT_GROUP_ID"] = this.atrtGroupId;
      this.result();
    },
    getDivGridNoAllocBtnCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.divGridNoAllocBtnDataText = data;

      this.detailData = [];
      let idx = 1;
      let dataText = this.divGridNoAllocBtnDataText;
      for (let i in dataText) {
        this.divGridNoAllocBtnDataText[i]["index"] = idx++;
      }
    },
    getDivGridAllocBtnCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.divGridAllocBtnDataText = data;
      this.detailData = [];
      let idx = 1;
      let dataText = this.divGridAllocBtnDataText;
      for (let i in dataText) {
        this.divGridAllocBtnDataText[i]["index"] = idx++;
      }
    },
    moveUp() {
      let btnGrid1_selected = this.selectedDivGridNoAllocBtnList;  // 미할당버튼 체크박스
      let btnGrid1 = this.divGridNoAllocBtnDataText;     // 미할당버튼
      let btnGgrid2 = this.divGridAllocBtnDataText;       // 할당버튼
      if (btnGrid1_selected.length > 0) {
        for(var i in btnGrid1_selected){
          btnGrid1.splice(btnGrid1.indexOf(btnGrid1_selected[i]) , 1);
          btnGgrid2.push(btnGrid1_selected[i]);
        }
        this.selectedDivGridNoAllocBtnList = [];
      } else {
        this.common_alert("미할당 버튼이 선택되지 않았습니다.","error");
      }
    },
    moveDown(){
      let btnGrid2_selected = this.selectedDivGridAllocBtnList;   // 할당버튼 체크박스
      let btnGrid1 = this.divGridNoAllocBtnDataText;     // 미할당버튼
      let btnGgrid2 = this.divGridAllocBtnDataText;       // 할당버튼
      if (btnGrid2_selected.length > 0) {
        for(var i in btnGrid2_selected) {
          btnGgrid2.splice(btnGgrid2.indexOf(btnGrid2_selected[i]), 1);
          btnGrid1.push(btnGrid2_selected[i]);
        }
        this.selectedDivGridAllocBtnList = [];
      } else {
        this.common_alert("할당 버튼이 선택되지 않았습니다.","error");
      }
    },
    submitBtnGrid1(){//저장
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/system/menu-author-manage/button-author/regist";
      this.headers["SERVICE"] = "setting.system.menu-author-manage.button-author";
      this.headers["METHOD"] = "regist";
      this.headers["ASYNC"] = false;
      this.headers["SUBMIT"] ="getlistsel";
      let submitList = [];
      let headersObj = {
        DATA_FLAG:"D",
        ATRT_GROUP_ID:this.atrtGroupId,
        MENU_ID :this.selectedMenu.MENU_ID,
      }
      submitList.push(headersObj);
      for(let i in this.divGridAllocBtnDataText){
        let obj = {
          divGridAlloc_CHECKBOX:'0',
          SCR_BTN_ID: this.divGridAllocBtnDataText[i].SCR_BTN_ID,
          SCR_BTN_NM: this.divGridAllocBtnDataText[i].SCR_BTN_NM,
          BTN_TYPE: this.divGridAllocBtnDataText[i].BTN_TYPE,
          BTN_ID: this.divGridAllocBtnDataText[i].BTN_ID,
          ATRT_GROUP_ID: this.atrtGroupId,
          MENU_ID: this.divGridAllocBtnDataText[i].MENU_ID,
          REGR_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          DATA_FLAG:"I",
        };
        submitList.push(obj);
      }
      this.sendData  = {
        list:submitList
      }
      this.result();
    },
    submitBtnGridCallBack(response){//
      this.common_alert("정상처리 되었습니다.","done");
      this.getListSel(this.selectedMenu);
    },
    getListOrNot(){
      if(this.mixin_isEmpty(this.menuGroup)) {
        this.common_alert('메뉴그룹을 선택해주세요.', 'error');
      }else if(this.mixin_isEmpty(this.atrtGroupId)){
        this.common_alert('권한그룹을 선택해주세요.', 'error');
      } else{
        this.getList();
      }
    },
    getList(){
      this.getDivGridNoAlloc();//미할당 사용자
      this.getDivGridAlloc();//할당사용자
    },
    getDivGridNoAlloc(){
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/system/menu-author-manage/un-asgn-menu/inqire";
      this.headers["METHOD"] = "inqire";
      this.headers["SERVICE"] = "setting.system.menu-author-manage.un-asgn-menu";
      this.headers["ASYNC"] = false;
      this.headers["GRID_ID"] = "divGridNoAlloc";

      this.sendData= {};
      this.sendData["MENU_GROUP"] = this.menuGroup;
      this.sendData["ATRT_GROUP_ID"] = this.atrtGroupId;
      this.result();
    },
    getDivGridAlloc(){
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/system/menu-author-manage/asgn-menu/inqire";
      this.headers["SERVICE"] = "setting.system.menu-author-manage.asgn-menu";
      this.headers["METHOD"] = "inqire";
      this.headers["ASYNC"] = false;
      this.headers["GRID_ID"] = "divGridAlloc";

      this.sendData= {};
      this.sendData["MENU_GROUP"] = this.menuGroup;
      this.sendData["ATRT_GROUP_ID"] = this.atrtGroupId;
      this.result();
    },
    getDivGridNoAllocCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.divGridNoAllocDataText = data;
      let idx = 1;
      let dataText = this.divGridNoAllocDataText;
      for (let i in dataText) {
        this.divGridNoAllocDataText[i]["index"] = idx++;
      }
      this.selectedDivGridNoAllocList = [];
      this.divGridAllocBtnDataText = [];
      this.divGridNoAllocBtnDataText = [];
      this.detailData = [];
    },
    getDivGridAllocCallBack(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.divGridAllocDataText = data;
      let idx = 1;
      let dataText = this.divGridAllocDataText;
      for (let i in dataText) {
        this.divGridAllocDataText[i]["index"] = idx++;
      }
      this.selectedDivGridAllocList =[];
      this.divGridAllocBtnDataText = [];
      this.divGridNoAllocBtnDataText = [];
      this.selectedMenu = '';
    },
    moveRight() {
      
      let grid1_selected = this.selectedDivGridNoAllocList;  // 미할당 사용 체크박스
      let grid1 = this.divGridNoAllocDataText;     // 미할당사용자 그리드
      let grid2 = this.divGridAllocDataText;       // 할당사용자 그리드
      if (grid1_selected.length > 0) {
        for(var i in grid1_selected){
          grid1.splice(grid1.indexOf(grid1_selected[i]) , 1);
          grid2.push(grid1_selected[i]);
        }
        this.selectedDivGridNoAllocList = [];
      } else {
        this.common_alert("미할당 메뉴가 선택되지 않았습니다.","error");
      }
    },
    moveLeft(){
      let grid2_selected = this.selectedDivGridAllocList;   // 할당 사용 체크박스
      let grid1 = this.divGridNoAllocDataText;     // 미할당사용자 그리드
      let grid2 = this.divGridAllocDataText;       // 할당사용자 그리드
      if (grid2_selected.length > 0) {
        for(var i in grid2_selected) {
          grid2.splice(grid2.indexOf(grid2_selected[i]), 1);
          grid1.push(grid2_selected[i]);
        }
        this.selectedDivGridAllocList = [];
      } else {
        this.common_alert("할당 메뉴가 선택되지 않았습니다.","error");
      }
    },
    submitGrid1(){//저장
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/system/menu-author-manage/regist";
      this.headers["SERVICE"] = "setting.system.menu-author-manage";
      this.headers["METHOD"] = "regist";
      this.headers["ASYNC"] = false;
      this.headers["SUBMIT"] ="getlist";
      let submitList = [];
      let headersObj = {
        DATA_FLAG:"D",
        MENU_GROUP:this.menuGroup,
        ATRT_GROUP_ID:this.atrtGroupId,
      }
      submitList.push(headersObj);
      for(let i in this.divGridAllocDataText){
        let obj = {
          divGridAlloc_CHECKBOX:'0',
          LOWRNK_MENU_ID: "",
          ATRT_GROUP_ID: this.atrtGroupId,
          ATRT_GROUP_NM: this.divGridAllocDataText[i].ATRT_GROUP_NM,//권한그룹명
          MENU_NM: this.divGridAllocDataText[i].MENU_NM,//메뉴명
          MENU_ID: this.divGridAllocDataText[i].MENU_ID,
          REGR_DEPT_CD: 'x',
          REGR_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          DATA_FLAG:"I",
        };
        submitList.push(obj);
      }
      this.sendData  = {
        list:submitList
      }
      this.result();
    },
    submitGridCallBack(response){//
      this.common_alert("정상처리 되었습니다.","done");
      this.getList();
    },

    getGrid1_ListSel(item){
      this.detailData = JSON.parse(JSON.stringify(item));
    },
    isActiveRow1(item){
      return item.MENU_ID== this.detailData.MENU_ID? 'is-active':'';
    },
    isActiveRow2(item){
      return item.MENU_ID== this.selectedMenu.MENU_ID? 'is-active':'';
    },
    isActiveRow3(item, row){
      return item.BTN_ID== this.detailData.BTN_ID? 'is-active':'';
    },
    isActiveRow4(item){
      return item.BTN_ID== this.detailData.BTN_ID? 'is-active':'';
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      this[`dialog${type}`] = false;
    },

    async result(requestData){
      let head='';
      let dataa='';
      //결과값을 보여주는 부분 -> 실제 사용 시 dataa의 value를 사용
      //api 호출 부분
      await api.post(this.URLData,   //api url입력
          //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
          this.sendData
          , //api에서 사용될 data 입력
          {head: this.headers
          }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
          .then((response) => {
            // head = JSON.stringify(response.data.HEADER);
            //dataa = JSON.stringify(response.data.DATA);
            if(typeof(response.data) === "string"){
              let responseData = JSON.parse(response.data.replaceAll("\n", ""));
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: true,
                msg: responseData.HEADER.ERROR_MSG,
                iconClass: "svg-error-lg",
                type: "default",
              });
              return;
            }
            if(response.data.HEADER.METHOD == "inqire" &&response.data.HEADER.GRID_ID =="divGridNoAlloc"){
              this.getDivGridNoAllocCallBack(response.data);
            }else if(response.data.HEADER.METHOD == "inqire" &&response.data.HEADER.GRID_ID =="divGridAlloc") {
              this.getDivGridAllocCallBack(response.data);
            }else if(response.data.HEADER.METHOD == "regist" &&response.data.HEADER.SUBMIT == "getlist") {
              this.submitGridCallBack(response.data);
            }else if(response.data.HEADER.METHOD == "regist" &&response.data.HEADER.SUBMIT == "getlistsel") {
              this.submitBtnGridCallBack(response.data);
            }else if(response.data.HEADER.METHOD == "inqire" &&response.data.HEADER.GRID_ID =="divGridAllocBtn") {
              this.getDivGridAllocBtnCallBack(response.data);
            }else if(response.data.HEADER.METHOD == "inqire" &&response.data.HEADER.GRID_ID =="divGridNoAllocBtn") {
              this.getDivGridNoAllocBtnCallBack(response.data);
            }else if(response.data.HEADER.METHOD == "inqire" &&response.data.HEADER.SERVICE =="setting.system.menu-author-manage.group") {
              this.menuGroupInqireCallBack(response.data);
            }else if(response.data.HEADER.METHOD == "inqire" &&response.data.HEADER.SERVICE =="setting.system.menu-author-manage.author-group-code") {
              this.groupCodeInqireCallBack(response.data);
            }
          }) //api 호출 성공 이후 수행
          .catch((err) => {
            this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.", "error");
          }) //api호출 에러 작업 수행
      this.HEADERResult = head;
      this.DATAResult = dataa;
      //결과값 보여주기
    }
  },
  mounted() {
    console.log("aaaaa")
    this.menuGroupInqire();
    this.groupCodeInqire();
  },

  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    initHeaders(){//getDivGridNoAlloc
      return this.headers = {
        "SERVICE": 'setting.system.menu-author-manage.un-asgn-menu',
        "METHOD" : "",
        "TYPE" :'BIZ_SERVICE',
        "GRID_ID":"",

      };
    },
  },
};
</script>

<style>
</style>
